"use client";
// import { blogData3 } from "@/data/Data";
import { LOAD_BLOG } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import BlogEntity from "@/types/BlogEntity";
import ServerDataProp from "@/types/ServerDataProp";
import dayjs from "dayjs";
import Link from "next/link";
import { useEffect, useState } from "react";
import CustomGsapHeaderAnimate from "../utils/CustomGsapHeaderAnimate";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";


const BlogSection: React.FC<ServerDataProp> = ({ setting }) => {
  const [blogData, setBlogData] = useState<BlogEntity[]>([]);
  useEffect(() => {
    callApi.get(LOAD_BLOG).then(res => {
      setBlogData(res.data);
    })
  }, [])

  return (
    <section className="rv-20-blog_section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="rv-20-blog_section_heading">
              <CustomGsapHeaderAnimate>
                <p className="rv-20-blog_sub_title rv-text-anime d-flex">
                  <span></span> Tin tức & Sự kiện
                </p>
              </CustomGsapHeaderAnimate>
              <CustomGsapHeaderAnimate>
                <h2 className="rv-20-blog_section_title rv-text-anime">
                  Bài viết mới nhất
                </h2>
              </CustomGsapHeaderAnimate>
            </div>
          </div>
        </div>

        <DivAnimateYAxis className="row">
          {blogData.slice(0, 3).map((item) => (
            <div className="col-md-6 col-lg-4" key={item._id}>
              <div className="rv-20-single_blog">
                <div className="rv-20-blog_image">
                  <Link href={`/blog/${item.slug}`}>
                    <img src={item.imageUrl} alt="image" />
                  </Link>

                  <p className="rv-20-single_blog_date">
                    <i className="fal fa-calendar-alt"></i>
                    {dayjs(item.date).format("DD-MM-YYYY")}
                  </p>
                </div>
                <h4 className="rv-20-single_blog_content_title">
                  <Link href={`/blog/${item.slug}`}>{item.title}</Link>
                </h4>
                <p className="rv-20-single_blog_content_desc">
                  {item.desc}
                </p>
                <Link
                  href={`/blog/${item.slug}`}
                  className="rv-20-single_blog_btn"
                >
                  Xem thêm <i className="far fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          ))}
        </DivAnimateYAxis>
      </div>
    </section>
  );
};

export default BlogSection;
