"use client";
import { LOAD_PROJECT } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import Project from "@/types/Project";
import ServerDataProp from "@/types/ServerDataProp";
import Link from "next/link";
import { useEffect, useState } from "react";
import CustomGsapHeaderAnimate from "../utils/CustomGsapHeaderAnimate";
import CustomImageAnimate from "../utils/CustomImageAnimate";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";


const ProjectSection3: React.FC<ServerDataProp> = ({ setting }) => {
  const [projectData, setProjectData] = useState<Project[]>([]);
  useEffect(() => {
    callApi.get(LOAD_PROJECT).then(res => {
      setProjectData(res.data);
    })
  }, [])
  return (
    <section className="rv-9-projects pb-60 pt-60">
      <div className="container">
        <DivAnimateYAxis className="rv-inner-projects">
          <div className="row g-xl-4 g-3 row-cols-lg-3 row-cols-sm-2 row-cols-1">
            {projectData.map((item) => (
              <Link href={`/projects/${item.slug}`} className="col" key={item._id}>
                <div className="rv-3-project rv-9-project rv-inner-project">
                  <div className="rv-3-project__img">
                    {
                      item.imageUrl ? <CustomImageAnimate src={item.imageUrl} alt={item.name} /> : <div className="no_image" style={{ backgroundColor: item.bgColor || 'white' }}>

                      </div>
                    }

                    <div className="rv-3-project__actions">
                      <div className="rv-3-project__actions">
                        <button className="quick-view">
                          <i className="fa-light fa-magnifying-glass"></i>
                        </button>
                        <span>
                          <i className="fa-light fa-link-simple"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="rv-9-project__txt">
                    <CustomGsapHeaderAnimate>
                      <span className="rv-3-project__sub-title-1" dangerouslySetInnerHTML={{ __html: item.desc }}>

                      </span>
                    </CustomGsapHeaderAnimate>
                    <CustomGsapHeaderAnimate>
                      <h5 className="rv-9-project__title">
                        <span>
                          {item.name}
                        </span>
                      </h5>
                    </CustomGsapHeaderAnimate>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </DivAnimateYAxis>
      </div>
    </section>
  );
};

export default ProjectSection3;
