"use client";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
type Inputs = {
  email: string;
  checkbox: boolean;
};

const CtaForm = () => {
  const { register, handleSubmit, reset } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    // Perform any additional actions before or after submitting data
    // Show a success toast
    toast.success("Đăng ký thành công!");

    // Reset the form to default values
    reset();
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="rv-6-footer-nwsltr__form rv-14-nwsltr__form"
    >
      <div className="nwsltr-left">
        <input
          type="email"
          placeholder="Nhập địa chỉ thư..."
          autoComplete="true"
          required
          {...register("email")}
        />
        <div className="rv-6-footer-nwsltr__checkbox rv-14-nwsltr__checkbox">
          <input
            type="checkbox"
            id="nwsltr-checkbox"
            value="1"
            {...register("checkbox")}
          />
          <label htmlFor="nwsltr-checkbox">
            {" "}
            Tôi đồng ý với <a href="#">Chính sách riêng tư</a>.
          </label>
        </div>
      </div>
      <button>
        <i className="fa-light fa-paper-plane"></i>{" "}
        <span className="txt">Đăng ký</span>
      </button>
    </form>
  );
};

export default CtaForm;
