"use client";
import { LOAD_BLOG } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import BlogEntity from "@/types/BlogEntity";
import dayjs from "dayjs";
import Link from "next/link";
import { useEffect, useState } from "react";
import CustomGsapHeaderAnimate from "../utils/CustomGsapHeaderAnimate";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";
const AllBlogSection = () => {
  const [blogData, setBlogData] = useState<BlogEntity[]>([]);

  useEffect(() => {
    // const params = new URLSearchParams(window.location.search);
    let queryString = window.location.search;
    // console.log({ queryString })
    callApi.get(LOAD_BLOG + queryString).then(res => {
      setBlogData(res.data);
    })
  }, [])
  return (
    <section className="rv-inner-blogs rv-section-spacing">
      <div className="container">
        <div className="rv-inner-blogs__row">
          {Array.isArray(blogData) && blogData?.map((item, index) => (
            <DivAnimateYAxis
              duration={1.2 + 0.1 * (index + 1)}
              className={`rv-1-blog rv-inner-blog ${item.big ? "rv-inner-blog--big" : ""
                } ${item.small ? "rv-inner-blog--small" : "rv-inner-blog--small"}`}
              key={item._id}
            >
              <div className="rv-1-blog__img">
                <img src={item.imageUrl} alt="Blog Image" />
              </div>

              <div className="rv-1-blog__txt">
                <ul className="rv-1-blog__infos">
                  <li>
                    <img src="assets/img/rv-1-icon-4.png" alt="icon" />{" "}
                    {dayjs(item.date).format("DD-MM-YYYY")}
                  </li>
                  <li>
                    <img src="assets/img/rv-1-icon-5.png" alt="icon" />{" "}
                    {item.comments || 0} Bình luận
                  </li>
                </ul>
                <CustomGsapHeaderAnimate>
                  <h4 className="rv-1-blog__title">
                    <Link href={`/blog/${item.slug}`}>{item.title}.</Link>
                  </h4>
                </CustomGsapHeaderAnimate>

                <Link
                  href={`/blog/${item.slug}`}
                  className="rv-1-schedule-conf__btn rv-1-blog__btn"
                >
                  Xem thêm <i className="fa-light fa-arrow-right"></i>
                </Link>
              </div>
            </DivAnimateYAxis>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AllBlogSection;
