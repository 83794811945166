"use client";
import { LOAD_FAQ } from "@/services/api/api_constance";
import { callApi } from "@/services/api/base_api";
import Faq from "@/types/Faq";
import ServerDataProp from "@/types/ServerDataProp";
import { useEffect, useState } from "react";
import CustomGsapHeaderAnimate from "../utils/CustomGsapHeaderAnimate";
import DivAnimateYAxis from "../utils/DivAnimateYAxis";

const AccordionSection: React.FC<ServerDataProp> = ({ setting }) => {
  const [expandedItem, setExpandedItem] = useState<string | null>('');
  const [faqData, setFaqData] = useState<Faq[]>([]);
  useEffect(() => {
    callApi.get(LOAD_FAQ).then(res => {
      setFaqData(res.data);
    })
  }, [])
  const handleItemClick = (itemId: string) => {
    setExpandedItem(expandedItem === itemId ? null : itemId);
  };

  return (
    <DivAnimateYAxis className="rv-accordion">
      <CustomGsapHeaderAnimate>
        <h3 className="rv-service-details__title">
          Câu hỏi thường gặp
        </h3>
      </CustomGsapHeaderAnimate>
      {faqData.map((item) => (
        <div
          key={item._id}
          className={`rv-accordion-item ${item._id === expandedItem ? "open" : ""
            }`}
          onClick={() => handleItemClick(item._id)}
        >
          <div className="rv-accordion-item-header">
            <h6 className="rv-accordion-item-title">{item.title}</h6>
            <span className="rv-accordion-item-expand-icon"></span>
          </div>
          <p className="rv-accordion-item-body" >
            {
              item._id == expandedItem && <span dangerouslySetInnerHTML={{ __html: item.desc || '' }} />
            }
          </p>
        </div>
      ))}
    </DivAnimateYAxis>
  );
};

export default AccordionSection;
